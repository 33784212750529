import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import GameButton from '../components/buttons/gameButton';
import BottomLine from '../components/elements/bottomLine';
import Link from 'next/link';
import {logEvent} from '../utils/analytics';

const getPlatform = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('windows')) return 'Windows';
    if (userAgent.includes('android')) return 'Android';
    if (userAgent.includes('macintosh') || userAgent.includes('mac os x')) return 'MacOS';
    if (userAgent.includes('linux')) return 'Linux';
    if (userAgent.includes('iphone') || userAgent.includes('ipad')) return 'iOS';
    return 'Windows';
};

const platformData = {
    Windows: {
        icon: '/icons/windows.svg',
        link: 'https://decision-storage.s3.eu-north-1.amazonaws.com/decision.exe',
    },
    MacOS: {
        icon: '/icons/ios.svg',
        link: 'https://decision-storage.s3.eu-north-1.amazonaws.com/decision.pkg'
    },
    Linux: {
        icon: '/icons/linux.svg',
        link: 'https://decision-storage.s3.eu-north-1.amazonaws.com/decision.zip'
    },
    iOS: {
        icon: '/icons/ios.svg',
        link: 'https://testflight.apple.com/join/MebriwMr'
    },
    Android: {
        icon: '/icons/android.svg',
        link: 'https://play.google.com/store/apps/details?id=com.decision.crypto'
    }
};

export default function Home() {
    const [platform, setPlatform] = useState('Windows');

    useEffect(() => {
        setPlatform(getPlatform());
    }, []);

    const handleDownloadClick = (platform: string) => {
        logEvent('User', 'Download', platform);
    };

    return (
      <section className='relative flex flex-col items-center justify-center lg:min-h-[calc(100vh+24px)]'>
        {/* Видео */}
        <div className='hidden lg:block absolute inset-0 overflow-hidden lg:h-[calc(100vh)]'>
          <video loop autoPlay muted playsInline className='w-full h-full object-cover'>
            <source src='/video/fire.mp4' type='video/mp4'/>
          </video>
        </div>

        {/* Фоновая картинка */}
        <div className='hidden lg:block absolute inset-0 z-20 pointer-events-none'>
          <Image
            className='object-fill w-full h-full'
            src='/background/game/Ramka.webp'
            alt='background frame'
            fill
            loading='lazy'
          />
        </div>
        <div className='absolute inset-0 z-10 bg-opacity-80 bg-black'>
          <Image
            className='object-cover lg:object-fill w-full h-full opacity-40'
            src='/background/game/WinterBackground.webp'
            alt='background frame'
            fill
            loading='lazy'
          />
        </div>

        {/* Центральный контейнер */}
        <div className='relative z-30 flex flex-col justify-center items-center text-center px-2 mt-8 mb-8'>
          <h1 className='text-[32px] xl:text-[80px] lg:text-[60px] leading-tight text-white font-georgia font-[700] w-full drop-shadow-[4px_4px_1px_black]'>Start playing now</h1>
          <h2 className='text-[16px] lg:text-[20px] text-white drop-shadow-[0px_1px_1px_black] font-ubuntu font-[400] leading-tight mt-4'>
            Immerse yourself in a world of decisions and consequences. <br /> Download the Decision Arena Version 1.2.1 Build 2 now to start your adventure!
          </h2>
          <div className='flex text-white pr-4'>
            <Link href={platformData[platform].link} target="_blank" rel="noopener noreferrer" onClick={() => handleDownloadClick(platform)}>
              <GameButton
                    className='inline-flex items-center px-6 py-4 text-[18px] lg:px-8 lg:py-4 lg:text-[20px] mt-8 mb-8'
                    button='gameButtonGray.webp'
                >
                Get {platform}
              </GameButton>
            </Link>
          </div>
          <p className='flex text-[16px] lg:text-[16px] text-white drop-shadow-[0px_1px_1px_black] font-ubuntu font-[400] leading-tight mb-8'>
            Also available on
            {Object.keys(platformData).filter(p => p !== platform).map((p, index) => (
              <span key={p} className='text-green-500 ml-4'>
                <Link href={platformData[p].link} target="_blank" rel="noopener noreferrer" onClick={() => handleDownloadClick(p)}>
                  {p}{index < Object.keys(platformData).filter(p => p !== platform).length - 1 ? ',' : ''}
                </Link>
              </span>
            ))}
          </p>
        </div>
        <div className='z-40 absolute bottom-0 w-full'>
          <BottomLine bottomLine={'yellow'} />
        </div>
      </section>
    );
}